// import axios from 'axios'
import axios from 'axios'
export default ({
  namespaced: true,
  state: {
    pabrik: null,
    kategori: null,
    golongan: null,
    kandungan: null,
    jenis: null,
    satuan: null,
    lokasi: null,
    khasiat :null,
    supplier: null,
    kategori_sp: null,
    loading: false
  },
  getters: {
    getPabrik(state){
      return state.pabrik
    },
    getKategori(state){
      return state.kategori
    },
    getGolongan(state){
      return state.golongan
    },
    getKandungan(state){
      return state.kandungan
    },
    getJenis(state){
      return state.jenis
    },
    getSatuan(state){
      return state.satuan
    },
    getLokasi(state){
      return state.lokasi
    },
    getkhasiat(state){
      return state.khasiat
    },
    getsupplier(state){
      return state.supplier
    },
    get_kategori_sp(state){
      return state.kategori_sp
    }
  },
  mutations: {
    setPabrik(state, value) {
        state.pabrik = value 
     },
    setKategori(state, value) {
        state.kategori = value 
     },
    setGolongan(state, value) {
        state.golongan = value 
     },
    setKandungan(state, value) {
        state.kandungan = value 
     },
    setJenis(state, value) {
        state.jenis = value 
     },
    setSatuan(state, value) {
        state.satuan = value 
     },
    setLokasi(state, value) {
        state.lokasi = value 
     },
    setkhasiat(state, value) {
        state.khasiat = value 
     },
    setsupplier(state, value) {
        state.supplier = value 
     },
    set_kt_supplier(state, value) {
        state.kategori_sp = value 
     },
    setLoading(state, value) { state.loading = value }
  },
  actions: {
    async fetchPabrik({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/pabrik`)
            .then((response) => {
                commit('setPabrik', response.data.data)
            })
            .catch(() => {
                commit('setPabrik', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchKategori({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/kategori`)
            .then((response) => {
                commit('setKategori', response.data.data)
            })
            .catch(() => {
                commit('setKategori', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchGolongan({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/golongan`)
            .then((response) => {
                commit('setGolongan', response.data.data)
            })
            .catch(() => {
                commit('setGolongan', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchKandungan({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/kandungan`)
            .then((response) => {
                commit('setKandungan', response.data.data)
            })
            .catch(() => {
                commit('setKandungan', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchJenis({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/jenis`)
            .then((response) => {
                commit('setJenis', response.data.data)
            })
            .catch(() => {
                commit('setJenis', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchSatuan({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/satuan`)
            .then((response) => {
                commit('setSatuan', response.data.data)
            })
            .catch(() => {
                commit('setSatuan', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchLokasi({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/lokasi`)
            .then((response) => {
                commit('setLokasi', response.data.data)
            })
            .catch(() => {
                commit('setLokasi', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchkhasiat({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/khasiat`)
            .then((response) => {
                commit('setkhasiat', response.data.data)
            })
            .catch(() => {
                commit('setkhasiat', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchsupplier({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/supplier`)
            .then((response) => {
                commit('setsupplier', response.data.data)
            })
            .catch(() => {
                commit('setsupplier', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchkt_supplier({ commit }) {
        commit('setLoading', true)
        await axios.get(`detail_produk/get_byjenisData/kategori_sp`)
            .then((response) => {
                commit('set_kt_supplier', response.data.data)
            })
            .catch(() => {
                commit('set_kt_supplier', null)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    
  },
 
})
