// import axios from 'axios'

export default ({
	namespaced: true,
  state: {
    tindakan: [],
    notifikasi:{
      judul: '',
      keterangan: '',
      status: '',
      konfirmasi: ''
    }
  },
  getters: {
    tindakan(state){
      return state.tindakan
    },
    notifikasi(state){
      return state.notifikasi
    },
    getJenisTindakan(){
      return [
        //tindakan umum
        { value:"WT", name: 'Wound Toilet' },
        { value:"GV", name: 'Ganti Verband' },
        { value:"INJ", name: 'Injection' },
        { value:"PK", name: 'Pasang Kateter' },
        { value:"HO", name: 'Hecting ON' },
        { value:"HA", name: 'Hecting AFF' },
        { value:"NB", name: 'Nebulizer' },
        { value:"EX", name: 'Exterpasi/Explorasi' },
        { value:"KH", name: 'Khitan' },
        { value:"LB", name: 'Luka Bakar' },
        { value:"IA", name: 'Incisi Abses' },
        { value:"SK", name: 'Surat Keterangan' },
        { value:"RT", name: 'Rectal Toucher' },
        { value:"RJP", name: 'Resusitasi Jantung Paru' },
        { value:"OK", name: 'Oksigen' },
        { value:"PEV", name: 'Pasang Elastic Verband' },
        { value:"GO", name: 'Kencing Nanah' },
        { value:"TH", name: 'Tampon Hidung' },
        { value:"KB", name: 'Kebidanan' },
        { value:"OB", name: 'Observasi' },
        { value:"RS", name: 'Rawat Sementara' },
        { value:"IN", name: 'Infus' },
        { value:"HC", name: 'Home Care' },
        { value:"LAB", name: 'Laboratorium' },
        { value:"GG", name: 'GIGI' },
      ]
    },
    getJenisTindakanKecantikan() {
      return [
        //tindakan kecantikan
        { value:"MIX", name: 'MIX' },
        { value:"SPA", name: 'Spa' },
        { value:"FA", name: 'Facial' },
        { value:"TL", name: 'Thred Lift' },
        { value:"CP", name: 'Chemical Peeling' },
        { value:"LASER", name: 'Laser' },
        { value:"OTHER", name: 'Other' },
      ]
    },
    getJenisTindakanLab(){
      return [
        {value:"KK", name: "KIMIA KLINIK"},
        {value:"HR", name: "HEMATOLOGI RUTIN"},
        {value:"IS", name: "IMMUNO-SEROLOGI"},
        {value:"U", name: "URINE"},
        {value:"UR", name: "URIN RUTIN"},
        {value:"M", name: "MIKROBIOLOGI"},
        {value:"P", name: "PAKET"},
      ]
    },

  },
  mutations: {
    SET_NOTIFIKASI_JUDUL(state, value) { state.notifikasi.judul = value },
    SET_NOTIFIKASI_KETERANGAN(state, value) { state.notifikasi.keterangan = value },
    SET_NOTIFIKASI_STATUS(state, value) { state.notifikasi.status = value },
    SET_NOTIFIKASI_KONFIRMASI(state, value) { state.notifikasi.konfirmasi = value },
  },
  actions: {
    aturNotifikasiBerhasil({ commit }){
      commit("SET_NOTIFIKASI_JUDUL", 'Berhasil')
      commit("SET_NOTIFIKASI_KETERANGAN", 'Berhasil Menambahkan Data')
      commit("SET_NOTIFIKASI_STATUS", 'success')
      commit("SET_NOTIFIKASI_KONFIRMASI", 'OK')
    },
    resetNotifikasi({ commit }){
      commit("SET_NOTIFIKASI_JUDUL", '')
      commit("SET_NOTIFIKASI_KETERANGAN", '')
      commit("SET_NOTIFIKASI_STATUS", ''),
      commit("SET_NOTIFIKASI_KONFIRMASI", '')
    },
    aturNotifikasiError({ commit }){
      commit("SET_NOTIFIKASI_JUDUL", 'Gagal')
      commit("SET_NOTIFIKASI_KETERANGAN", 'Gagal Menambahkan Data')
      commit("SET_NOTIFIKASI_STATUS", 'error'),
      commit("SET_NOTIFIKASI_KONFIRMASI", 'OK')
    }
  },
 
})
