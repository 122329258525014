// import axios from 'axios'
import {laravel} from '../main'

export default ({
  namespaced: true,
  state: {
    data: null,
    loading: null,
  },
  getters: {
    getData(state){
      return state.data;
    },
    getLoading(state){
      return state.loading;
    }
  },
  mutations: {
    setData(state, value) { 
        state.data = value 
    },
    setLoading(state, value) {
        state.loading = value
    }
  },
  actions: {
    async fetchDataOpen({commit}, params){
      commit("setLoading", true)
      await laravel.get(`api/shift/getWhereOpen/${params.cabang_id}/${params.id}`)
        .then((response) => {
          commit("setData", response.data ? response.data : null)
        })
        .catch(() => {
          commit("setData", null)
        })
        .finally(() => {
          commit("setLoading", false)
      })
    },
    resetData({commit}) {
      commit("setData", null)
    }
  }
})