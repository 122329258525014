<template>
	<div>
		<ContentHeader title="Reseptionis" subTitle="Tambah Data Pasien" url="/resepsionis" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header">
								<h3 class="card-title">Input Data Pasien</h3>
							</div>
							<div class="card-body">
								<form @submit.prevent="prosesPasien">
									<div class="card-header">
										<h5><i class="fas fa-user"></i></h5>
									</div>
									
									<div >
										<div class="card-body">
											<div class="row">
												<div class="col">
													<div class="px-lg-2">
														<div class="form-group">
															<label for="nomor_rekam_medis">Nomor Rekam Medis </label>
															<input type="text" class="form-control" id="nomor_rekam_medis"
																name="nomor_rekam_medis" disabled=""
																v-model="pasien.nomor_rekam_medis">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="nomor_rekam_medis_lama">Nomor Rekam Medis
																Lama</label>
															<input type="text" class="form-control"
																id="nomor_rekam_medis_lama" name="nomor_rekam_medis_lama"
																v-model="pasien.nomor_rekam_medis_lama">
														</div>
													</div>
													<div v-if="cekNik != true" class="px-lg-2">
														<div class="form-group">
															<label for="nomor_identitas">Nomor Identitas Kependudukan (NIK)
																<span class="text-danger">*</span></label>
															<input type="text" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="nomor_nik" @input="validateNik">
															<input type="checkbox" v-model="cekNik">
															<span class="ml-2 text-danger">*Centang jika WNA</span>
														</div>
													</div>
													<div v-if="cekNik" class="px-lg-2">
														<div class="form-group">
															<label for="nomor_identitas">Nomor Identitas Lain (Nomor Paspor
																/ KITAS) <span class="text-danger text-sm">*Khusus
																	WNA</span></label>
															<input type="number" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.nomor_wna">
															<input type="checkbox" v-model="cekNik">
															<span class="ml-2 text-danger">*Centang jika WNI</span>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="nama">Nama Lengkap<span
																	class="text-danger">*</span></label>
															<input type="text" class="form-control" id="nama"
																autocomplete="off" name="nama" v-model="pasien.nama"
																@keyup="generateRekamMedis(pasien.nama)" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="jenis_kelamin">Jenis Kelamin <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control" id="jenis_kelamin"
																name="jenis_kelamin" v-model="pasien.jenis_kelamin" required>
																<option selected></option>
																<option value="Tidak diketahui">Tidak diketahui</option>
																<option value="L">Laki-laki</option>
																<option value="P">Perempuan</option>
																<option value="Tidak dapat ditentukan">Tidak dapat
																	ditentukan</option>
																<option value="Tidak mengisi">Tidak mengisi</option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="tempat_lahir">Tempat Lahir <span
																	class="text-danger">*</span></label>
																	<el-select id="pasien" v-model="pasien.tempat_lahir" filterable remote reserve-keyword placeholder="Masukkan Kota" class="w-100"
																		:remote-method="getkotaLahir" :loading="loading_pasien">
																		<el-option v-for="(row, index) in kotaLahir" :key="index"
																			:label="row.nama" :value="row.nama" />
																	</el-select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="tanggal_lahir">Tanggal Lahir <span
																	class="text-danger">*</span></label>
															<input type="date" class="form-control" id="tanggal_lahir"
																name="tanggal_lahir" v-model="pasien.tanggal_lahir" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="pendidikan">Pendidikan <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control" id="pendidikan"
																name="pendidikan" v-model="pasien.pendidikan" required>
																<option selected></option>
																<option value="Tidak Sekolah">Tidak Sekolah</option>
																<option value="SD/MI">SD/MI</option>
																<option value="SLTP Sederajat">SLTP Sederajat</option>
																<option value="SLTA Sederajat">SLTA Sederajat</option>
																<option value="D1 - D3">D1 - D3</option>
																<option value="D4">D4</option>
																<option value="S1">S1</option>
																<option value="S2">S2</option>
																<option value="S3">S3</option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="golongan_darah">Golongan Darah <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control" id="golongan_darah"
																name="golongan_darah" v-model="pasien.golongan_darah" required>
																<option selected></option>
																<option value="-">-</option>
																<option value="A">A</option>
																<option value="B">B</option>
																<option value="AB">AB</option>
																<option value="O">O</option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="agama">Agama <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control" id="agama"
																name="agama" v-model="pasien.agama" required>
																<option selected></option>
																<option value="Islam">Islam</option>
																<option value="Kristen Protestan">Kristen Protestan</option>
																<option value="Kristen Katolik">Kristen Katolik</option>
																<option value="Hindu">Hindu</option>
																<option value="Budda">Budda</option>
																<option value="Konghucu">Konghucu</option>
																<option value="Penghayat">Penghayat</option>
																<option value="Lain-lain">Lain-lain</option>
															</select>
															<input type="text" v-if="pasien.agama == 'Lain-lain'"
																class="form-control mt-2">
														</div>
													</div>
													<div class="px-lg-2">
														<div class="form-group">
															<label for="status_pernikahan">Status Pernikahan</label>
															<select class="custom-select form-control"
																id="status_pernikahan" name="status_pernikahan"
																v-model="pasien.status_pernikahan">
																<option value="Belum Kawin">Belum Kawin</option>
																<option value="Kawin">Kawin</option>
																<option value="Cerai Hidup">Cerai Hidup</option>
																<option value="Cerai Mati">Cerai Mati</option>
															</select>
															
														</div>
													</div>
												</div>
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="suku">Suku <span
																	class="text-danger">*</span></label>
															<input type="text" class="form-control" id="suku" name="suku"
																v-model="pasien.suku">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="bahasa yang dikuasasi">Bahasa yang dikuasai</label>
															<input type="text" class="form-control"
																id="bahasa yang dikuasasi" autocomplete="off"
																name="bahasa yang dikuasasi"
																v-model="pasien.bahasa_yang_dikuasai">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="nomor_whatsapp">Nomor Telepon Rumah / Tempat Tinggal
																<span class="text-danger">*</span></label>
															<input type="number" class="form-control" id="nomor_whatsapp"
																autocomplete="off" name="nomor_whatsapp"
																v-model="pasien.nomor_telpon_rumah">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="nomor_telepon">Nomor Telepon Selular Pasien <span class="text-danger">*</span></label>
															<input type="number" class="form-control" id="nomor_telepon"
																autocomplete="off" name="nomor_telepon"
																v-model="pasien.nomor_selular" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="pekerjaan">Pekerjaan <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control" id="pekerjaan"
																name="pekerjaan" v-model="pasien.pekerjaan" required>
																<option selected></option>
																<option value="Tidak Bekerja">Tidak Bekerja</option>
																<option value="Pegawai Negeri Sipil">Pegawai Negeri Sipil</option>
																<option value="TNI/POLRI">TNI/POLRI</option>
																<option value="BUMN">BUMN</option>
																<option value="Pegawai Swasta / Wirausaha">Pegawai Swasta / Wirausaha</option>
																<option value="lainnya">Lainnya</option>
															</select>
															<input v-if="pasien.pekerjaan == 'lainnya'" type="text" class="form-control mt-2 ">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="alergi">Alergi <span
																	class="text-danger">*</span></label>
															<input class="form-control" id="alergi" name="alergi"
																v-model="pasien.alergi" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="nama_anggota_keluarga">Nama Ibu Kandung <span
																	class="text-danger">*</span></label>
															<input type="text" class="form-control"
																id="nama_anggota_keluarga" autocomplete="off"
																name="nama_anggota_keluarga"
																v-model="pasien.nama_ibu_kandung" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="nomor_kontak_darurat">Nomor Kontak Darurat <span
																	class="text-danger">*</span></label>
															<input type="number" class="form-control"
																id="nomor_kontak_darurat" autocomplete="off"
																name="nomor_kontak_darurat"
																v-model="pasien.nomor_kontak_darurat" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="sumber_informasi">Sumber Informasi <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control" id="sumber_informasi"
																name="sumber_informasi" v-model="pasien.sumber_informasi" required>
																<option selected></option>
																<option value="cuma_lewat">Cuma Lewat</option>
																<option value="instagram">Instagram</option>
																<option value="klinik_kesehatan">Klinik Kesehatan</option>
																<option value="brosur">Brosur</option>
																<option value="radio">Radio</option>
																<option value="website">website</option>
																<option value="teman">Teman</option>
																<option value="facebook">Facebook</option>
																<option value="whatsapp">whatsapp</option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="kategori_pasien">Kategori Pasien</label>
															<select class="custom-select form-control" id="kategori_pasien"
																name="kategori_pasien" v-model="pasien.kategori_pasien">
																<option value="🟡">🟡</option>
																<option value="🟢">🟢</option>
																<option value="PRB">PRB</option>
																<option value="Prolanis">Prolanis</option>
																<option value="PRB & Prolanis">PRB & Prolanis</option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="no_member">Nomor Member</label>
															<input type="text" class="form-control" id="no_member" name="no_member"
																v-model="pasien.nomor_member">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="point">Point</label>
															<input type="text" class="form-control" id="point" name="point"
																v-model="pasien.point">
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-header">
											<h5><i class="fas fa-address-card"></i></h5>
										</div>
										<div class="card-body">
											<h5 class="text-bold mt-2">Alamat Sesuai dengan KTP</h5>
											<div class="row">
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Provinsi <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																@change="getKota(pasien.provinsi)"
																v-model="pasien.provinsi" required>
																<option selected>Pilih Provinsi</option>
																<option v-for="row in provinsi" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kota / Kabupaten <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																@change="getKecamatan(pasien.kota)" v-model="pasien.kota" required>
																<option selected>Pilih Kota/Kabupaten</option>
																<option v-for="row in kota" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kecamatan <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																@change="getDesa(pasien.kecamatan)"
																v-model="pasien.kecamatan" required>
																<option selected>Pilih Kecamatan</option>
																<option v-for="row in kecamatan" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Desa <span class="text-danger">*</span></label>
															<select class="custom-select form-control"
																v-model="pasien.desa" required>
																<option selected>Pilih Desa</option>
																<option v-for="row in desa" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>

												</div>
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="alamat_lengkap">Alamat Lengkap <span
																	class="text-danger">*</span></label>
															<input type="text" class="form-control" id="alamat_lengkap"
																autocomplete="off" name="alamat_lengkap"
																v-model="pasien.alamat_lengkap" required>
														</div>
													</div>
													<div class="row">
														<div class="col">
															<div class="px-lg-2">
																<div class="form-group">
																	<label for="">RT <span
																			class="text-danger">*</span></label>
																	<input type="number" class="form-control"
																		id="nomor_identitas" autocomplete="off"
																		name="nomor_identitas" v-model="pasien.rt" required>
																</div>
															</div>
														</div>
														<div class="col">
															<div class="px-lg-2">
																<div class="form-group">
																	<label for="">RW <span
																			class="text-danger">*</span></label>
																	<input type="number" class="form-control"
																		id="nomor_identitas" autocomplete="off"
																		name="nomor_identitas" v-model="pasien.rw" required>
																</div>
															</div>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kode Pos <span
																	class="text-danger">*</span></label>
															<input type="number" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.kode_pos" required>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Negara <span class="text-danger">*</span></label>
															<input type="text" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.negara" required>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input type="checkbox" v-model="Centang">
													<span class="ml-2 text-bold">Samakan dengan Alamat KTP</span>
												</div>
											</div>
											<h5 class="text-bold mt-2">Domisili Sekarang</h5>
											<div v-if="Centang" class="row">
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Provinsi <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																
																v-model="pasien.provinsi" disabled>
																<option selected>Pilih Provinsi</option>
																<option v-for="row in provinsi" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kota / Kabupaten <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																v-model="pasien.kota" disabled>
																<option selected>Pilih Kota/Kabupaten</option>
																<option v-for="row in kota" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kecamatan <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																
																v-model="pasien.kecamatan" disabled>
																<option selected>Pilih Kecamatan</option>
																<option v-for="row in kecamatan" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Desa <span class="text-danger">*</span></label>
															<select class="custom-select form-control"
																v-model="pasien.desa" disabled>
																<option selected>Pilih Desa</option>
																<option v-for="row in desa" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
												</div>
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="alamat_lengkap">Alamat Lengkap <span
																	class="text-danger">*</span></label>
															<input type="text" class="form-control" id="alamat_lengkap"
																autocomplete="off" name="alamat_lengkap"
																v-model="pasien.alamat_lengkap" disabled>
														</div>
													</div>
													<div class="row">
														<div class="col">
															<div class="px-lg-2">
																<div class="form-group">
																	<label for="">RT <span
																			class="text-danger">*</span></label>
																	<input type="number" class="form-control"
																		id="nomor_identitas" autocomplete="off"
																		name="nomor_identitas" v-model="pasien.rt" disabled>
																</div>
															</div>
														</div>
														<div class="col">
															<div class="px-lg-2">
																<div class="form-group">
																	<label for="">RW <span
																			class="text-danger">*</span></label>
																	<input type="number" class="form-control"
																		id="nomor_identitas" autocomplete="off"
																		name="nomor_identitas" v-model="pasien.rw" disabled>
																</div>
															</div>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kode Pos <span
																	class="text-danger">*</span></label>
															<input type="number" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.kode_pos" disabled>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Negara <span class="text-danger">*</span></label>
															<input type="text" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.negara" disabled>
														</div>
													</div>
												</div>
											</div>
											<div v-else class="row">
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Provinsi <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																@change="getKotaDom(pasien.provinsi_domisili)"
																v-model="pasien.provinsi_domisili">
																<option selected>Pilih Provinsi</option>
																<option v-for="row in provinsiDom" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kota / Kabupaten <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																@change="getKecamatanDom(pasien.kotakab_domisili)" v-model="pasien.kotakab_domisili">
																<option selected>Pilih Kota/Kabupaten</option>
																<option v-for="row in kotaDom" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kecamatan <span
																	class="text-danger">*</span></label>
															<select class="custom-select form-control"
																@change="getDesaDom(pasien.kecamatan_domisili)"
																v-model="pasien.kecamatan_domisili">
																<option selected>Pilih Kecamatan</option>
																<option v-for="row in kecamatanDom" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Desa <span class="text-danger">*</span></label>
															<select class="custom-select form-control"
																v-model="pasien.kelurahan_domisili">
																<option selected>Pilih Desa</option>
																<option v-for="row in desaDom" :key="row.kode"
																	v-bind:value="row.kode"><span class="text-capitalize">{{
																		row.nama }}</span></option>
															</select>
														</div>
													</div>

												</div>
												<div class="col">
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="alamat_lengkap">Alamat Lengkap <span
																	class="text-danger">*</span></label>
															<input type="text" class="form-control" id="alamat_lengkap"
																autocomplete="off" name="alamat_lengkap"
																v-model="pasien.alamat_domisili">
														</div>
													</div>
													<div class="row">
														<div class="col">
															<div class="px-lg-2">
																<div class="form-group">
																	<label for="">RT <span
																			class="text-danger">*</span></label>
																	<input type="number" class="form-control"
																		id="nomor_identitas" autocomplete="off"
																		name="nomor_identitas" v-model="pasien.rt_domisili">
																</div>
															</div>
														</div>
														<div class="col">
															<div class="px-lg-2">
																<div class="form-group">
																	<label for="">RW <span
																			class="text-danger">*</span></label>
																	<input type="number" class="form-control"
																		id="nomor_identitas" autocomplete="off"
																		name="nomor_identitas" v-model="pasien.rw_domisili">
																</div>
															</div>
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Kode Pos <span
																	class="text-danger">*</span></label>
															<input type="number" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.kodepos_domisili">
														</div>
													</div>
													<div class="px-lg-2 ">
														<div class="form-group">
															<label for="">Negara <span class="text-danger">*</span></label>
															<input type="text" class="form-control" id="nomor_identitas"
																autocomplete="off" name="nomor_identitas"
																v-model="pasien.negara_domisili">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<label class="ml-1"><span class="text-danger" >Perhatiann..!!</span> Jika Nomor Rekam Medisnya Nan Atau Eror Segara Muat ulang Atau segara hubungi PJ IT</label>
									</div>
									<div class="ml-2 mb-2">
										Menyetujui
										<a href="#" data-toggle="modal" data-target="#exampleModalSK">
											Syarat dan Ketentuan
										</a>
										yang berlaku di {{ nama_cabang }}

									</div>
									<div class="px-lg-2">
										<button type="submit" class="btn btn-primary d-flex align-items-center">
											<div>
												Submit
											</div>
											<div>
												<svg v-if="loading" xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													style=" background: none; display: block; shape-rendering: auto;"
													width="200px" height="200px" viewBox="0 0 100 100"
													preserveAspectRatio="xMidYMid">
													<g>
														<path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843"
															fill="none" stroke="#ffffff" stroke-width="12"></path>
														<path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
														<animateTransform attributeName="transform" type="rotate"
															repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50"
															keyTimes="0;1"></animateTransform>
													</g>

												</svg>
											</div>
										</button>
									</div>
									<!-- /.card-body -->
								</form>
							</div>
						</div>
					</div>

					<!-- SYARAT DAN KETENTUAN -->
					<div class="modal fade bd-example-modal-lg" id="exampleModalSK" tabindex="-1" role="dialog"
						aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="exampleModalLabel">Syarat dan Ketentuan</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<div class="form-group">
										<p><strong>Kepemilikan dan isi rekam medis</strong></p>
										<ol>
											<li>Dokumen rekam medis milik {{ nama_cabang }}</li>
											<li>{{ nama_cabang }} bertanggung jawab atas hilang, rusak, pemalsuan dan/atau
												penggunaan oleh orang tidak berhak terhadap dokumen rekam medis </li>
											<li>{{ nama_cabang }} harus membuka akses seluruh isi RME ke Kemenkes. </li>
											<li>Kemenkes berwenang melalukan pemanfaatan dan penyimpanan isi RME dalam
												rangka pengolahan data Kesehatan.</li>
											<li>Isi rekam medis milik pasien dan dapat disampaikan kepada keluraga terdekat
												atau pihak lain setelah mendapat persetujuan pasien.</li>
											<li>Pihak lain meliputi :
												<ol>
													<li type="a">Pasien dibawah umur 18tahun; dan/atau</li>
													<li type="a">Pasien dalam keadaan darurat</li>
												</ol>
											</li>
											<li>Rekam medis harus diberikan kepada pasien rawat jalan apabila dibutuhkan.
											</li>
											<li>Rekam medis untuk pasien pulang berupa surat yang dikirim dan diterima dalam
												bentuk elektronik dengan menggunakan jaringan computer atau alat komunikasi
												elektronik lain termasuk ponsel atau dalam bentuk tercetak.</li>
										</ol>
										<p><strong>Keamanan dan perlindungan data</strong></p>
										<ol>
											<li>Pimpinan fasyankes memberikan hak akses kepada nakes dan/atau tenaga lain di
												fasyankes, untuk penginputan data, perbaikan data dan melihat data.</li>
											<li>Perbaikan data hanya dapat dilakukan oleh nakes pemberi pelayanan Kesehatan
												dan petugas admnistrasi termasuk perekam medis dan informasi Kesehatan
												dengan batas waktu paling lama 2 x 24 jam sejak data di input.</li>
											<li>Apabila kesalahan data administrative diketahui melebihi tenggat waktu,
												perbaikan data dilakukan setelah mendapat persetujan perekam medis dan
												informasi Kesehatan dan/atau pimpinan fasyankes.</li>
											<li>Pemberikan hak akses menjadi bagian dari kebijakan standar prosedur
												operasional penyelenggaraan rekam medis elektronik yang ditetapkan oleh
												pimpinan fasyankes</li>
											<li>Penyelengraan RME dapat dilengkapi dengan tanda tangan elektronik, yang
												digunakan sebagai alat verifikasi dan autentifikasi atas isi RME dan
												identitas penanda tangan.</li>
										</ol>
										<p><strong>Kerahasiaan isi RME</strong></p>
										<span>Isi rekam medis wajib dijaga kerahasiaan nya oleh semua pihak yang terlibat
											dalam pelayanan Kesehatan di fasyankes walaupun pasien telah meninggal
											dunia.</span>
										<p>Pihak yang terlibat dalam pelayanan di fasyankes meliputi :</p>
										<ol>
											<li>Nakes pemberi pelayanan Kesehatan, dokter dan dokter gigi, dan/atau nakes
												lain yang memiliki akses terhadap data dan informasi Kesehatan pasien.</li>
											<li>Pimpinan fasyankes.</li>
											<li>Tenaga yang berkaitan dengan pembiayaan pelayanan Kesehatan.</li>
											<li>Badan hukum/korporasi dan/atau fasyankes.</li>
											<li>Mahasiswa/siswa yang bertugas dalam pemeriksaan, pengobatan, perawat
												dan/atau manajemen informasi di fasyankes.</li>
											<li>Pihak lain yang memiliki akses terhadap data dan informasi Kesehatan pasien
												di fasynakes.</li>
										</ol>
										<p><strong>Pembukaan Isi Rekam Medis</strong></p>
										<ol>
											<li>Atas persetujuan pasien
												<ol>
													<li type="a">Kebutuhan pembukaan:
														<ul>
															<li>Kepentingan pemeliharaan kesehatan, pengobatan, penyembuhan,
																dan perawatan
																pasien</li>
															<li>Permintaan pasien sendiri</li>
															<li>Keperluan administrasi pembayaran asuransi atau jaminan
																pembiayaan kesehatan
															</li>
														</ul>
													</li>
													<li type="a">Pasien tidak cakap, persetujuan pembukaan isi rekam medis
														dapat diberikan oleh keluarga terdekat, ahli waris atau pengampunya.
													</li>
													<li type="a">Pembukaan rekam medis untuk keperluan administrasi,
														pembayaran asuransi jaminan pembiayaan Kesehatan dilakukan pada saat
														registrasi.</li>
												</ol>
											</li>
											<li>Tanpa persetujuan pasien
												<ol>
													<li type="a">Kebutuhan pembukaan:
														<ul>
															<li>Pemenuhan permintaan apparat penegak hukum dalam rangka
																penegakan hukum</li>
															<li>Penegak etik atau disiplin</li>
															<li>Audit medis</li>
															<li>Penanganan kejadian luar biasa/wabah penyakit
																menular/kedaruratan Kesehatan masyarakat/bencana</li>
															<li>Pendidikan dan penelitian</li>
															<li>Upaya perlindungan terhadap bahaya ancaman keselamatan orang
																lain secara individual atau masyarakat</li>
															<li>Lain yang diatus dalam peraturan perundang-undangan</li>
														</ul>
													</li>
													<li type="a">Dilakukan tanpa membuka identitas pasien</li>
													<li type="a">Permintaan diajukan oleh pihak atau institusi yang
														berwenang atas kepentingan tersebut.</li>
												</ol>
											</li>
										</ol>
										<p><strong>Pembukaann isi RME untuk :</strong></p>
										<ol>
											<li>Penangangan kejadian luar biasa/wabah penyakit menular/kedaruratan Kesehatan
												masyarakat/bencana; dan</li>
											<li>Upaya perlindungan terhadap bahaya ancaman keselamatan orang lain secara
												individual atau masyarakat identitas pasien dapat dibuka kepada institusi
												yang berwenang untuk dilakukan tindak lanjut sesuai dengan ketentuan
												peraturan perundang-undangan.</li>
										</ol>
										<p><strong>Pelepasan hak atas isi rekam medis</strong></p>
										<ol>
											<li>Pasien dan/atau keluarga pasien yang menginformasi isi Rekam Medis kepada
												publik melalui media massa dianggap telah melakukan pelepasan hak rahasia
												isi Rekam Medis kepada umum.</li>
											<li>Pelepasan hak rahasia isi rekam medis kepada umum memberikan kewenangan
												kepada fasyankes untuk mengungkapkan rahasia isi Rekam Medis sebagai hak
												jawab fasyankes.</li>
										</ol>
										<p><strong>Jangka waktu penyimpanan</strong></p>
										<ol>
											<li>Penyimpanan data RME di fasyankes dilakukan paling singkat 25 (dua puluh
												lima) tahun sejak tanggal kunjungan terakhir pasien.</li>
											<li>Setelah batas waktu tersebut berakhir, data RME dapat dikecualikan untuk
												apabila data tersebut masih akan dipergunakan atau dimanfaatkan.</li>
											<li>Pemusnahan RME dilakukan sesuai dengan ketentuan peraturan
												perundang-undangan.</li>
										</ol>



									</div>
								</div>
								<div class="modal-footer">
									<button type="button" class="btn btn-primary" data-dismiss="modal" @click="Agree(1)">Saya
										Setuju</button>
									<button type="button" class="btn btn-danger" data-dismiss="modal" @click="Agree(2)">Saya Tidak
										Setuju</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import { reactive, computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
// import { config } from '../../../main'
// import { ElMessage } from 'element-plus'

const user = computed(() => store.getters['auth/user'])
// const cabang = computed(() => store.getters['auth/cabang'])
const loading = ref(false)
const agreement = ref('')
const Centang = ref(false)
const cekNik = ref(false)
// Generate Kode Tindakan
const nama_cabang = ref()
const kode_cabang = ref('')
const kode_dari_nama = ref('')
const nomor_urut = ref('')
const cek = ref(0)

const Agree = async (index) => {
	if (index == 1) {
		agreement.value = 'Setuju'
		console.log(agreement.value)
		pasien.persetujuan = agreement.value
	} else if (index == 2) {
		agreement.value = 'Tidak Setuju'
		pasien.persetujuan = agreement.value
		console.log(agreement.value)
	}
}

const validateNik = async () => {
    if(nomor_nik.value.length > 16) {
        nomor_nik.value = nomor_nik.value.slice(0, 16);
    }
}




console.log(Centang.value)

const getCabang = async () => {
	await axios.get(`cabang/getById/${user.value.data.tbCabangId}`)
		.then((response) => {
			kode_cabang.value = `${response.data.data.kode_cabang}`
			nama_cabang.value = `${response.data.data.nama}`
		})
		.catch((error) => {
			console.log(error)
		})

	pasien.nomor_rekam_medis = `${kode_cabang.value}-`
}

const tambah_nol = (x) => {
	let y = (x > 9) ? (x > 99) ? (x > 999) ? (x > 9999) ? (x > 99999) ? x : '0' + x : '00' + x : '000' + x : '0000' + x : '00000' + x;
	return y;
}


const generateRekamMedis = (nama) => {

	if (nama == '') {
		pasien.nomor_rekam_medis = ``
	} else {
		kode_dari_nama.value = nama.slice(0, 1).toUpperCase()

		if (kode_cabang.value.length > 0 && kode_dari_nama.value != '') {
			axios.get(`pasien_kecantikan/getGenereateKode/${user.value.data.tbCabangId}/${kode_cabang.value}`)
				.then((response) => {
					console.log(response.data)
					if (response.data.data == null) {
						cek.value = 1
						nomor_urut.value = tambah_nol(1)
						generateKode()
					} else {
						let str = response.data.data.nomor_rekam_medis
						cek.value = 1
						let length = str.length
						let nlength = length - 6
						let id = str.slice(nlength, length)
						console.log(id)
						id = Math.abs(id)
						nomor_urut.value = tambah_nol(id + 1)
						generateKode()
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
}

const generateKode = () => {
	pasien.nomor_rekam_medis = `${kode_cabang.value}-${nomor_urut.value}`
}

// End

const pasien = reactive({
	'tbCabangId': user.value.data.tbCabangId,
	'nomor_rekam_medis': '',
	'nama': '',
	'nomor_nik': '',
	"provinsi": null,
	"kota": null,
	"kecamatan": null,
	"desa": null,
	'alamat_lengkap': '',
	'nomor_telpon_rumah': '',
	'nomor_selular': '',
	'jenis_kelamin': '',
	'golongan_darah': '',
	'tempat_lahir': '',
	'tanggal_lahir': '',
	'agama': '',
	'pekerjaan': '',
	'pendidikan': '',
	'sumber_informasi': '',
	'foto': '',
	'nomor_kontak_darurat': '',
	'status_member': false,
	'nomor_rekam_medis_lama': null,
	'asuransi': null,
	'nomor_asuransi': null,
	'nama_ibu_kandung': null,
	'status_pernikahan': null,
	'alergi': null,
	'nomor_wna': '',
	'alamat_domisili': '',
	'rt_domisili': '',
	'rw_domisili': '',
	'kelurahan_domisili': null,
	'kecamatan_domisili': null,
	'kotakab_domisili': null,
	'kodepos_domisili': '',
	'provinsi_domisili': null,
	'negara_domisili': '',
	'suku': '',
	'bahasa_yang_dikuasai': '',
	'rt': '',
	'rw': '',
	'negara': '',
	'kode_pos': '',
	'persetujuan': '',
	'kategori_pasien': null,
	'point': null,
	'nomor_member': null,
})

const unknown = reactive({
	'perkiraan_umur': '',
	'lokasi_ditemukan': '',
	'tanggal_ditemukan': '',
	'identitas_penanggung_jwb_pasien': '',
	'nama_penanggung_jwb_pasien': '',
	'nohp_penanggung_jwb_pasien': '',
	'hub_dgn_pasien': '',
	'hub_lain_lain': '',
	'iden_pengantar_pasien': '',
	'nama_pengantar_pasien': '',
	'nohp_pengantar_pasien': '',
	'tbCabangId': user.value.data.tbCabangId
})


const nomor_nik = ref('');

const sendNomorNik = () => {
	console.log(nomor_nik.value)
	pasien.nomor_nik = `${nomor_nik.value}`
}



const resetDataPasien = () => {
	pasien.nama = '',
	pasien.nomor_identitas = '',
	pasien.alamat_lengkap = '',
	pasien.nomor_telpon = '',
	pasien.nomor_whatsapp = '',
	pasien.jenis_kelamin = '',
	pasien.golongan_darah = '',
	pasien.tempat_lahir = '',
	pasien.tanggal_lahir = '',
	pasien.agama = '',
	pasien.pekerjaan = '',
	pasien.pendidikan = '',
	pasien.sumber_informasi = '',
	// pasien.sosial_media = '',
	// pasien.nomor_member = '',
	// pasien.label = '',
	pasien.foto = '',
	pasien.nomor_kontak_darurat = '',
	pasien.status_member = false,
	pasien.asuransi = null,
	pasien.nomor_asuransi = null,
	pasien.nama_anggota_keluarga = null
	pasien.status_pernikahan = null
	pasien.alergi = null

	// UNKNOWN
	unknown.perkiraan_umur = '',
	unknown.lokasi_ditemukan = '',
	unknown.tanggal_ditemukan = '',
	unknown.identitas_penanggung_jwb_pasien = '',
	unknown.nama_penanggung_jwb_pasien = '',
	unknown.nohp_penanggung_jwb_pasien = '',
	unknown.hub_dgn_pasien = '',
	unknown.hub_lain_lain = '',
	unknown.iden_pengantar_pasien = '',
	unknown.nama_pengantar_pasien = '',
	unknown.nohp_pengantar_pasien = ''
}




const prosesPasien = async () => {
    console.log('nomor_nik:', nomor_nik.value);
    if (nomor_nik.value && nomor_nik.value.length === 16) {
		sendNomorNik()
        doSubmit('create');
    } else {
        Swal.fire({
            title: 'Gagal!',
            text: 'Masukkan NIK 16 Digit',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
        });
    }
}

const checkConsole = localStorage.getItem('console');
console.log(checkConsole);

const ihs = ref({
    ihs: null,
});

const doSubmit = (val) => {
    if (Centang.value === true) {
        pasien.kecamatan_domisili = pasien.kecamatan;
        pasien.kelurahan_domisili = pasien.desa;
        pasien.kotakab_domisili = pasien.kota;
        pasien.provinsi_domisili = pasien.provinsi;
    }
    loading.value = true;
    axios.post(`pasien_kecantikan/${val}`, pasien)
        .then((response) => {
            if (response.data.message === `PASIEN a.n ${pasien.nama} / NIK : ${pasien.nomor_nik} SUDAH TERDAFTAR DI SYSTEM`) {
                Swal.fire({
                    title: 'Gagal!',
                    text: response.data.status,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3000
                });
                loading.value = false;
            } else {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Berhasil Menambahkan Data',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
                loading.value = false;
                resetDataPasien();
                updateIhsPatient(response.data.post.id);
                console.log(response);
                if (checkConsole == null) {
                    router.push('/resepsionis');
                } else {
                    localStorage.setItem('console', '00d2088472bdf05ad135b408a861114f');
                    setTimeout(() => {
                        location.reload();
                    }, 3000);
                }
            }
        })
        .catch((error) => {
            Swal.fire({
                title: 'Gagal!',
                text: 'Gagal Menambahkan data',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500
            });
            loading.value = false;
            console.log(error);
        });
};




const updateIhsPatient = async (id) => {
  await axios.put(`pasien/updateIhs/${id}`, ihs.value)
  .then(() => {
    Swal.fire({
      title: 'Berhasil!',
      text: 'Berhasil Menambahkan Data',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500
    })
  })
  .catch((error) => {
    Swal.fire({
      title: 'Gagal!',
      text: 'Gagal Menambahkan Data',
      icon: 'error',
      confirmButtonText: 'Ok'
    })
    console.log(error)
  })
}

// Foto handle
// const foto = ref(null)
// const foto_dom = ref('')
// const inputImage = (e) => {
// 	let files = e.target.files || e.dataTransfer.files
// 	if (files[0].type == 'image/png' || files[0].type == 'image/jpeg') {
// 		if (files[0].size > 2000000) {
// 			pasien.foto = ''
// 			foto_dom.value.value = ''
// 			foto.value = null
// 			ElMessage({
// 				message: 'Ukuran file harus kurang dari 2MB.',
// 				type: 'warning',
// 			})
// 		} else {
// 			pasien.foto = files[0]
// 			createImage(files[0]);
// 		}
// 	} else {
// 		pasien.foto = ''
// 		foto_dom.value.value = ''
// 		foto.value = null
// 		ElMessage({
// 			message: 'Format file harus png atau jpeg.',
// 			type: 'warning',
// 		})
// 	}
// }

// const createImage = (file) => {
// 	let reader = new FileReader();

// 	reader.onload = (e) => {
// 		foto.value = e.target.result;
// 	}

// 	if (file) reader.readAsDataURL(file)
// }
// End foto handle

// Get Provinsi
const provinsi = ref([])

const getProvinsi = async () => {
	let response = await axios.get(`wilayah/getprovinsi`)

	provinsi.value = response.data.data
}
const provinsiDom = ref([])

const getProvinsiDom = async () => {
	let response = await axios.get(`wilayah/getprovinsi`)

	provinsiDom.value = response.data.data
}

// End Get Provinsi

// Get Kota
const kota = ref([])

const getKota = async (kode) => {
	let response = await axios.get(`wilayah/getkota/${kode}`)
	kota.value = response.data.data
}
// End Get Kota

// Get kecamatan
const kecamatan = ref([])

const getKecamatan = async (kode) => {
	let response = await axios.get(`wilayah/getkecamatan/${kode}`)
	kecamatan.value = response.data.data
}
// End Get kecamatan

// Get desa
const desa = ref([])

const getDesa = async (kode) => {
	let response = await axios.get(`wilayah/getdesa/${kode}`)
	desa.value = response.data.data
}
// End Get desa

// Get Kota
const kotaDom = ref([])

const getKotaDom = async (kode) => {
	let response = await axios.get(`wilayah/getkota/${kode}`)
	kotaDom.value = response.data.data
}
// End Get Kota

// Get kecamatan
const kecamatanDom = ref([])

const getKecamatanDom = async (kode) => {
	let response = await axios.get(`wilayah/getkecamatan/${kode}`)
	kecamatanDom.value = response.data.data
}
// End Get kecamatan

// Get desa
const desaDom = ref([])

const getDesaDom = async (kode) => {
	let response = await axios.get(`wilayah/getdesa/${kode}`)
	desaDom.value = response.data.data
}
// End Get desa

// Get kotaLahir
const kotaLahir = ref([])

const getkotaLahir = async (nama) => {
	let name = undefined
	if (nama == undefined) {
		name = 'emptyParameter'
	} else {
		name = nama
	}
	let response = await axios.get(`wilayah/getkotaLahir/${name}?page=1`)
	kotaLahir.value = response.data.data
}
// End Get kotaLahir



onMounted(() => {
	getCabang()
	getProvinsi()
	getProvinsiDom()
	getkotaLahir()
})
</script>

<style>.hijau {
	background-color: #10B981;
}

.kuning {
	background-color: #FCD34D;
}

.merah {
	background-color: #EF4444;
}

.hitam {
	background-color: #1F2937;
}

.ungu {
	background-color: #8B5CF6;
}

.biru {
	background-color: #3B82F6;
}</style>