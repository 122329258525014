<template>
	<li class="nav-item menu-open" v-if=" jabatanId !== 1">
		<!-- style="background-color: #ff975e;" -->
	    <router-link to="/" class="nav-link active" >
	      <i class="nav-icon fas fa-tachometer-alt"></i>
	      <p>
	        Dashboard
	      </p>
	    </router-link>
	</li>
</template>
<script setup>
import store from '@/store'
import { computed } from 'vue'
const user = computed(() => store.getters['auth/user'])
const jabatanId = computed(() => user.value.data.tb_karyawan ? user.value.data.tb_karyawan.jabatanId : null)
</script>