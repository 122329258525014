import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'
import { laravel } from '../main'

export default ({
	namespaced: true,
  state: {
  	authenticated: true,
  	user: null,
    icdx: null,
    logo: null,
    cabang: null,
    cabangs: null,
    hak_akses: null,
    level_jabatan: null,
    karyawan: null
  },
  getters: {
  	authenticated(state){
  		return state.authenticated;
  	},
  	user(state){
  		return state.user;
  	},
    icdx(state){
      return state.icdx;
    },
    cabang(state){
      return state.cabang;
    },
    cabangs(state){
      return state.cabangs;
    },
    logo(state){
      return state.logo;
    },
    hak_akses(state){
      return state.hak_akses
    },
    level_jabatan(state){
      return state.level_jabatan
    },
    karyawan(state){
      return state.karyawan
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value){ state.authenticated = value },
    SET_USER(state, value) { state.user = value },
    SET_ICDX(state, value) { state.icdx = value },
    SET_CABANG(state, value) { state.cabang = value },
    SET_CABANGS(state, value) { state.cabangs = value },
    SET_LOGO(state, value) { state.logo = value },
    SET_HAK_AKSES(state, value) { state.hak_akses = value },
    SET_LEVEL_JABATAN(state, value) { state.level_jabatan = value },
    SET_KARYAWAN(state, value) { state.karyawan = value },
  },
  actions: {
    async login ({dispatch}, credentials){
      await laravel.get('sanctum/csrf-cookie')
      try {
        const response = await axios.post('login', credentials)  
        localStorage.setItem('token', response.data.token + " /" + response.data.id)
        const tokenLaravel = await laravel.post('api/login', credentials) 
        localStorage.setItem('tokenLaravel', tokenLaravel.data.token)
        localStorage.setItem('tbUserId', response.data.id);
        dispatch('me')
      } catch {
        return 'wrong_credential'
      }
    },


    async logout({ commit }){
      let token = localStorage.getItem('token');
      let id = token.split("/")[1]; 
      await axios.post('/user/logout', { userId: id });
      
      localStorage.removeItem('token')
      localStorage.removeItem('tokenLaravel')
      localStorage.removeItem('buttonClicked')
      localStorage.removeItem('tbUserId')
        Swal.fire({
          text: 'Berhasil logout',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        commit("SET_AUTHENTICATED", false)
        commit("SET_USER", null)
        router.replace('/login')
      .catch(() => {
        Swal.fire({
          text: 'Gagal Logout',
          icon: 'error',
          confirmButtonText: 'Ok'
        })  
      })
    },

    async me({commit}){
      try{
        let token = localStorage.getItem('token')
        let id = token.split("/")
        let response  = await axios.get('user/' + id[1])
        // console.log(response)
        let cabang    = await axios.get(`cabang/getById/${response.data.data.tbCabangId}`)
        let cabangs    = await laravel.get(`api/cabang/getWhereId/${response.data.data.tbCabangId}`)
        let hak_akses = await laravel.get(`api/hak_akses/getMenu/${response.data.data.id}`)
        let karyawan  = await axios.get(`karyawan/getById/${response.data.data.tbKaryawanId}`)

        commit("SET_AUTHENTICATED", true)
        commit("SET_USER", response.data)
        commit("SET_CABANG", cabang.data)
        commit("SET_CABANGS", cabangs.data)
        commit("SET_LOGO", cabang)
        commit("SET_HAK_AKSES", hak_akses.data)
        commit("SET_KARYAWAN", karyawan.data)
        commit("SET_LEVEL_JABATAN", (karyawan.data.data != null) ? karyawan.data.data.levelJabatanId : 5)
        router.replace('/absensi-karyawan')
        router.push('/absensi-karyawan')
      } catch {
        commit("SET_AUTHENTICATED", false)
        commit("SET_USER", null)
        commit("SET_CABANG", null)
        commit("SET_LOGO", null)
        commit("SET_CABANGS", null)
        commit("SET_HAK_AKSES", null)
        commit("SET_KARYAWAN", null)
        commit("SET_LEVEL_JABATAN", null)
      }
    },

    async getIcdx({commit}){
      try{
        let response = await axios.get('api/icdx/get')
        commit("SET_ICDX", response.data)
      } catch {
        commit("SET_USER", null)
      }
    }
  },
})
