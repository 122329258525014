<template>
	<div class="hold-transition login-page"
		style="background: rgb(41,128,185);
background: linear-gradient(148deg, rgba(41,128,185,1) 0%, rgb(255, 255, 255) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, rgba(255,106,136,1) 100%);">
		<LoadingScreen v-if="loading_screen" />
		<div class="w-full d-flex flex-column mb-2" style="height: auto;">
			<img :src="image ? image : defaultImage" alt=""
				style="width: 255px;height: 250px;display: block;margin-left: auto;margin-right: auto;">
		</div>
		<div class="text-center text-sm font-weight-bold">{{ cabang.nama }}</div>
		<div class="text-center text-sm font-weight-bold">{{ cabang.alamat }}</div>
		<div class="text-center text-sm font-weight-bold">No Izin Klinik : {{ cabang.sio }}</div>
		<div class="text-center text-sm font-weight-bold">Phone : {{ cabang.telepon }}</div>
		<br>
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
					<div class="card-header ">
						<div class="text-center">
							<h1>Selamat Datang Di</h1>
							<h1 class="text-bold">ANJUNGAN PENDAFTARAN MANDIRI</h1>
							<h5>Silakan menekan tombol untuk Mengisi data Pasien</h5>
						</div>
					</div>
					<div class="card-body" style="display: block;">
						<div class="row justift-content-center">
							<div class="col">
								<button style="width: 100%;" type="submit" class="btn btn-danger py-3" @click="tambahPasien">PASIEN BARU
								</button>
							</div>
						</div>
					</div>
					<!-- /.card-body -->
					<div class="card-footer" style="display: block;">

					</div>
					<!-- /.card-footer-->
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
// import ContentHeader from '@/components/ContentHeader'
import LoadingScreen from '@/components/LoadingScreen'
import { computed, ref } from 'vue'
import store from '@/store'

// const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabangs'])
const logo = computed(() => store.getters['auth/logo'])
const image = ref()
image.value = logo.value.config.baseURL + '/' + logo.value.data.data.logo

const tambahPasien = () => {
	localStorage.removeItem('console')
	localStorage.setItem('console', 'tambahPasien')
	location.reload()
}

</script>