<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': farmasiActive}">
	    <a href="#" class="nav-link" @click="farmasiActive = !farmasiActive">
	      <i class="nav-icon fas fa-tablets"></i>
	      <p>
	        Farmasi/Inventori/Persediaan
	        <!-- <i class="right fas fa-angle-left"></i> -->
	      </p>
	    </a>
	    <ul class="nav nav-treeview">
	      <li class="nav-item pl-2" v-if="props.akses.includes('aturan_pakai') || props.akses.includes('semua') && cabang.jenis == 'pusat' && cabang.tipe.toLowerCase() != 'kecantikan'">
	        <router-link to="/aturan-pakai" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Aturan Pakai</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_obat') || props.akses.includes('semua') && cabang.jenis == 'pusat' && cabang.tipe.toLowerCase() != 'kecantikan'">
	        <router-link to="/data-obat" class="nav-link">
			 <i class="nav-icon fas fa-tablets"></i>
	          <p class="text-sm">Data Obat/Kosmetik</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_percetakan') || props.akses.includes('semua') && cabang.jenis == 'pusat' && cabang.tipe.toLowerCase() != 'kecantikan'">
	        <router-link to="/data-percetakan" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Data Percetakan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_alat_kesehatan') || props.akses.includes('semua') && cabang.jenis == 'pusat' && cabang.tipe.toLowerCase() != 'kecantikan'">
	        <router-link to="/data-alat-kesehatan" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Data Alat Kesehatan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_produk_kecantikan') || props.akses.includes('semua') && cabang.jenis == 'pusat' && cabang.tipe.toLowerCase() == 'kecantikan'">
	        <router-link to="/data-produk-kecantikan" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Data Produk Kecantikan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_alat_kesehatan') || props.akses.includes('semua') && cabang.jenis == 'pusat'">
	        <router-link to="/data-detail-produk" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Data Detail Produk</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('pembelian_obat') || props.akses.includes('semua') && cabang.jenis == 'pusat'">
	        <router-link to="/pembelian-obat" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Pembelian Produk</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2">
	        <router-link to="/purchasing-order" class="nav-link" v-if="props.akses.includes('purchasing_order') || props.akses.includes('semua') && cabang.jenis == 'cabang'">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Purchasing Order</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2">
	        <router-link to="/pemesanan-obat" class="nav-link" v-if="props.akses.includes('pemesanan_obat') || props.akses.includes('semua') && cabang.jenis == 'cabang'">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Pembelian</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2">
	        <router-link to="/pemesanan-obat-kegudang" class="nav-link" v-if="props.akses.includes('pemesanan_obat_kegudang') || props.akses.includes('semua') && cabang.jenis == 'cabang'">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Pemesanan Ke Gudang</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('penjualan_obat') || props.akses.includes('semua')">
	        <router-link to="/penjualan-obat" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Penjualan Produk</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_alat_kesehatan') || props.akses.includes('semua')">
	        <router-link to="/penjualan-langsung" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Penjualan Langsung</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('stok_produk') || props.akses.includes('semua')">
	        <router-link to="/stok-produk" class="nav-link">
			  <i class="nav-icon fas fa-tablets"></i>
	          <p class="text-sm">Stok Produk</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('stok_opname') || props.akses.includes('semua')">
	        <router-link to="/stok-opname" class="nav-link">
			  <i class="nav-icon fas fa-tablets"></i>
	          <p class="text-sm">Stok Opname</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('kartu_stok') || props.akses.includes('semua')">
	        <router-link to="/kartu-stok" class="nav-link">
			  <i class="nav-icon fas fa-tablets"></i>
	          <p class="text-sm">Kartu Stok</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_supplier') || props.akses.includes('semua')">
	        <router-link to="/data-supplier" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Data Supplier</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_pelanggan') || props.akses.includes('semua')">
	        <router-link to="/data-pelanggan" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Data Pelanggan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('racikan_obat') || props.akses.includes('semua')">
	        <router-link to="/racikan-obat" class="nav-link">
			  <i class="nav-icon fas fa-tablets"></i>
	          <p class="text-sm">Racikan Obat</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('lokasi_obat') || props.akses.includes('semua')">
	        <router-link to="/lokasi-produk" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Lokasi Produk</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2">
	        <router-link to="/produk-terintegrasi-kfa" class="nav-link">
	          <i class="far fa-circle nav-icon"></i>
	          <p class="text-sm">Produk Terintegrasi KFA</p>
	        </router-link>
	      </li>
	    </ul>
	  </li>
</template>

<script setup>
import store from '@/store'
import { ref, defineProps, computed } from 'vue'

const cabang = computed(() => store.getters['auth/cabangs'])
const farmasiActive = ref(false)

const props = defineProps({
  akses: Array
})
  
</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>