<template>
  <div id="app">
    <div class="mb-20"></div>
    <Anjungan v-if="consoles == '00d2088472bdf05ad135b408a861114f'"/>
    <AnjunganBPJS v-else-if="consoles == '996ab9c0cbf34581cc9b188397fc54c1'"/>
    <AnjunganNonBPJS v-else-if="consoles == '8c151762f0320b2fc380b5583cc2e64a'"/>
    <Display v-else-if="consoles == '07306d7744f2656c904d5bcea291aa4e'"/>
    <DisplayFarmasi v-else-if="consoles == '94cb6d020f7b0d4e830286ef33797799'"/>
    <DisplayDokter v-else-if="consoles == '59993112d26ac0cdc46949857989601f'"/>
    <div v-else-if="consoles == 'tambahPasien'">
    <div class="row mx-2 my-2">
      <div class="col">
        <a href="#" class="btn btn-danger" @click="kembaliPasienBaru">Kembali</a>
      </div>
    </div>
      <TambahDataPasien />
    </div>
    <div v-else>
      <div v-if="authenticated" >
        <!-- <div v-if="toggle" style="position: absolute;width: 100%;height: 100%;background-color: black;opacity: 0.6;z-index: 2000;">Loading</div> -->
   
        <Navbar />
        <Sidebar />
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
          <!-- Main Content -->
            <router-view></router-view>
          <!-- End Main Content -->
        </div>
        <!-- <Footer /> -->
      </div>
      <Login v-else />  
    </div>


  </div>
</template>


<script setup>
	import store from '@/store'
  import { computed, watch } from 'vue'
  import Navbar from '@/components/Navbar'
  import Anjungan from '@/components/anjungan'
  import AnjunganBPJS from '@/components/anjunganbpjs'
  import AnjunganNonBPJS from '@/components/anjungannonbpjs'
  import Display from '@/components/Display'
  import DisplayFarmasi from '@/components/DisplayFarmasi'
  import DisplayDokter from '@/components/DisplayDokter'
  import Sidebar from '@/components/Sidebar'
  import Login from '@/views/auth/Login'
  import TambahDataPasien from '@/views/pelayanan/TambahDataPasien'
  
  import { io } from 'socket.io-client';
  import Swal from 'sweetalert2';
  import { urlSocket } from '@/main';
  const user = computed(() => store.getters['auth/user'])
  const jabatanId = computed(() => user.value.data.tb_karyawan ? user.value.data.tb_karyawan.jabatanId : null)
  const socket = io(urlSocket.baseUrl, {
    withCredentials: true,
  });

  socket.on('notification', ({ type, message, division }) => {
  console.log(`Received notification: ${message}`);
  
  if (type === 'broadcast') {
    Swal.fire({
      title: 'Pesan Broadcast System',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  } else if (type === 'broadcastByDivision' && division) {
    if (jabatanId.value === division || jabatanId.value === null) {
      let divisionName = '';
      switch (division) {
        case 1:
          divisionName = 'Dokter';
          break;
        case 2:
          divisionName = 'Operasional';
          break;
        case 3:
          divisionName = 'Beautician';
          break;
        case 4:
          divisionName = 'Asisten Apoteker';
          break;
        case 5:
          divisionName = 'Apoteker';
          break;
        case 6:
          divisionName = 'Asisten Perawat';
          break;
        case 7:
          divisionName = 'Front Office';
          break;
        case 8:
          divisionName = 'Marketing';
          break;
        case 9:
          divisionName = 'Administrasi';
          break;
        case 10:
          divisionName = 'Administrasi Farmasi';
          break;
        case 11:
          divisionName = 'keuangan bendahara dan produksi';
          break;
        case 12:
          divisionName = 'Perawat';
          break;
        case 13:
          divisionName = 'administrasi apotek';
          break;
        case 14:
          divisionName = 'Riset';
          break;
        default:
          divisionName = 'Divisi Lain';
      }

      Swal.fire({
        title: `Pesan Broadcast Untuk ${divisionName}`,
        text: message,
        icon: 'info',
        confirmButtonText: 'Ok',
      });
    }
  }
});

  const authenticated  = computed(() => store.getters['auth/authenticated'])
  const cabang         = computed(() => store.getters['auth/cabangs'])
  const consoles = localStorage.getItem('console')


  const generateToken = () => {
    if(cabang.value?.ihs && cabang.value?.secret_key && cabang.value?.client_key) {
      store.dispatch('satusehat/generateToken', { ihs: cabang.value?.ihs, secret_key: cabang.value?.secret_key, client_key: cabang.value?.client_key })
    }
  }
  
  watch(() => authenticated.value, () => {
    generateToken()
  })
  
  generateToken()

const kembaliPasienBaru = () => {
  localStorage.removeItem('console')
	localStorage.setItem('console', '00d2088472bdf05ad135b408a861114f')
	location.reload()
}

if (consoles != null) {
    const fungsiYangAkanDijalankan = () => {
      localStorage.removeItem('console')
    }

    const aturInterval = () => {
      // const sekarang = new Date();
      const waktuHingga16JamBerikutnya = 14 * 60 * 60 * 1000;
      setInterval(fungsiYangAkanDijalankan, waktuHingga16JamBerikutnya);
    }
    aturInterval();
}

// Hapus token setelah 1 jam
setInterval(() => {
    localStorage.removeItem('tokenSatuSehat'); 
    // Panggil generateToken lagi setelah 1 jam
    generateToken()
}, 60 * 60 * 1000);

</script>
<style>

</style>
